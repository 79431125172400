
.background {
  overflow: hidden;
  background: #777777;
  background: -moz-linear-gradient(left, #777777 0%, #999999 100%);
  background: -webkit-linear-gradient(left, #777777 0%, #999999 100%);
  background: linear-gradient(to right, #777777 0%, #999999 100%);
}

.content {
  padding: 5%;
  width: 80% !important;
  height: auto;
  word-break: break-word;
  position: absolute;
  font-family: Verdana, sans-serif;
  font-size: xx-large;
}

code {
  font-weight: 900;
  animation-name: cyber;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes cyber {
  0% {color: darkgreen;}
  25% {color: green;}
  50% {color: seagreen;}
  75% {color: forestgreen;}
  100% {color: darkgreen;}
}
